@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"



































































































































































.report-problem
  &__responsive-drawer
    &__header
      +flex-space-between
      min-height: 80px
      padding: $size-s
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25)
      margin-bottom: 12px

    &__body
      padding: $font-size-xs $size-s
      padding-bottom: 80px

  &__action
    position: absolute
    bottom: 0
    width: 100%
    padding: $size-s
    +flex-column-end

    &__send
      width: 100%

  .report-problem__form
    &__label
      font-size: $font-size-heading-5-small
      font-weight: $font-weight-semi-bold
      margin-bottom: $size-s

      &:not(:first-child)
        margin-top: $size-xl

    &__field
      +flex-center-start
      margin-bottom: $size-s
      margin-left: 10px

      input
        width: $size-m
        height: $size-m

      label
        font-size: $size-s
        margin-left: $font-size-xs
        font-weight: normal

    &__reason
      resize: none
      width: 100%
      height: 106px
      background: #FFFFFF
      border: 1px solid #C9CCCF
      border-radius: 8px
